<template>
  <div>

  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      orderTradeNo: "",
      form: "",
      channel: ""
    };
  },
  created() {

  },
  mounted() {
    this.payAliLoad()
  },
  methods: {
    payAliLoad() {
      // 定义正则表达式模式来匹配参数
      console.log(this.$route.fullPath.toString())
      const decodedParams = decodeURIComponent(this.$route.fullPath.toString());
      // console.log(decodedParams)
      // 根据特定的分隔符拆分字符串
      const parts = decodedParams.split('&orderTradeNo=');
      // 提取 form 参数
      const form = parts[0].substring(parts[0].indexOf('<form'));
      // 提取 orderTradeNo 参数
      const orderTradeNo = parts[1].split('&channel=')[0];
      // 提取 channel 参数
      const channel = parts[1].split('&channel=')[1];
      this.form = form
      this.orderTradeNo = orderTradeNo
      this.channel = channel
      this.$message.success("正在调起支付宝支付");
      console.log("调起支付宝支付")
      document.querySelector("body").innerHTML = this.form;
      document.forms[0].submit();
    },
  },
}
</script>
<style scoped></style>